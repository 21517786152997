@font-face {   
  font-family: 'Montserrat';   
  src: url('./assets/Montserrat.ttf') format('truetype'); }

body {
  margin: 0;
  font-family: 'Montserrat', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

