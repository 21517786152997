.footerFinal{
    width: 100%;
    background-color: #015441;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    color: white;
    padding-block: 30px;
}

.informationSideFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-inline: 20px;
    width: 80%;
    margin-inline: auto;
}

.rightReserved a{
    outline: none;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.rightReserved{
    width: 80%;
    margin-inline: auto;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 2px solid white;
}

@media screen and (max-width: 768px) {
    .informationSideFooter{
        flex-direction: column;
        gap: 30px
    }
}
