.introduction{
    width: 100%;
    color: #015441;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-block-start: 30px;
}

.IntroductionImgSide{
    width: 60%;
    position: relative;
    display: flex;
}

.imageIntroduction{
    width: 80%;
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 600px;
}

.IntroductionTextSide{
    width: 38%;
}







.leaf {
    width: 30px;
    position: absolute;
  }
  .leaf:nth-child(1) {
    bottom: 5%;
    left: -5%;
    animation: blowing 32s 10s linear infinite;
  }
  .leaf:nth-child(2) {
    bottom: 10vh;
    left: -5%;
    animation: blowing 26s linear infinite;
  }
  .leaf:nth-child(3) {
    bottom: 0vh;
    left: -5%;
    animation: blowing 19s 7s linear infinite;
  }
  @keyframes blowing {
    0% {
      transform: translate(0,0) rotate(0)
    }
    20% {
      transform: translate(15vw, -10vh) rotate(170deg)
    }
    65% {
      transform: translate(55vw, -12vh) rotate(550deg)
    }
    80% {
      transform: translate(70vw, -17vh) rotate(740deg)
    }
    100% {
      transform: translate(100vw, -25vh) rotate(920deg)
    }
  }

  @media screen and (max-width: 768px) {
    .leaf{
      display: none;
    }
    .introduction{
      flex-direction: column;
      width: 100%;
      margin-top: 200px;
      margin-bottom: 40px;
    }
    .imageIntroduction{
      display: none;
    }
    .IntroductionTextSide{
      width: 90%;
    }
    .svgIntro{
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 945px) {
    .introduction{
      align-items: flex-end;
    }
  }

  @media screen  and (max-width: 401px) {
    .introduction{
      margin-top: 10px;
    }
  }