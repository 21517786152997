.App {
  text-align: center;
  background-color: #fff;
  background: url('./assets/leaf.svg');
}

h1{
  font-size: 20px;
}

h2{
  font-size: 19px;
}

h3{
  font-size: 18px;
}

h4{
  font-size: 16px;
}

p{
  font-size: 16px;
}

.xs{background-color: #05050509}


