.introduction{
    width: calc(100% - 60px);
    background-color: #015441;
    color: #fff;
    display: flex;
    flex-direction: row;
    padding-inline: 30px;
    padding-block: 100px;
}

.IntroductionImgSide{
    width: 60%;
    position: relative;
}

.imageIntroduction{
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 40px;
    max-width: 600px;
}

.IntroductionTextSide{
    padding-block: 20px;
    width: 50%;
    text-align: justify;

}

@media screen and (max-width: 768px) {
    .introduction{
        flex-direction: column-reverse;
        width: 100%;
        padding-inline: 0;
        text-align: center;
    }
    .IntroductionTextSide{
        width: 90%;
        margin-inline: auto;
        text-align: center;
    }
    .imageIntroduction{
        height: 300px;
        position: relative;
    }
    .IntroductionImgSide{
        width: 90%;
        margin-inline: auto;
    }
    
    .IntroductionTextSide{
        padding-block: 0;
        padding-bottom: 20px;
    }
}
