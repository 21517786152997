.header {
    background-color: transparent;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    z-index: 100000;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 1120px;
  }

  .headerScrolled{
    background-color: white;
    color: #015441;
  }


  .logoImage {
    width: 200px;
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: transparent;
  }
  
  .header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    color: white;
  }
  
  .header li a:hover,
  .header .menuBtn:hover {
    border-bottom: 6px solid white;
    border-radius: 5px;
  }
  
  .header .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding-inline-start: 20px;
    padding-block: 20px;
    text-decoration: none;
  }
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  .header .menuIcon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 38px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menuIcon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 24px;
  }
  
  .header .menuIcon .navicon:before,
  .header .menuIcon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .header .menuIcon .navicon:before {
    top: 5px;
  }
  
  .header .menuIcon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .header .menuBtn {
    display: none;
  }
  
  .header .menuBtn:checked ~ .menu {
    max-height: 240px;
  }
  
  .header .menuBtn:checked ~ .menuIcon .navicon {
    background: transparent;
  }
  
  .header .menuBtn:checked ~ .menuIcon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .header .menuBtn:checked ~ .menuIcon .navicon:after {
    transform: rotate(45deg);
  }
  
  .header .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:before,
  .header .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:after {
    top: 0;
  }

@media screen and (max-width: 1067px) {
  .header {
    display: block;
  }
}

@media screen and (max-width: 340px) {
  .header .logo{
    font-size: 30px;
  }
  .logospan, .letra{
    font-size: 40px;
  }

  .header .menuIcon{
    padding-inline: 5px;
  }
}


  /* RESPONSIVE */ 
  
  @media screen and (min-width: 1067px) {
    .header li {
        float: left;
      }
      .header li a {
        padding: 30px 16px;
      }
      .header .menu {
        clear: none;
        float: right;
        max-height: none;
      }
      .header .menuIcon {
        display: none;
      }
      
  }

  @media screen and (max-width: 1067px) {
    .header{
      background-color: white;
      color: #015441;
    }
    .header li a {
      color: #015441;
    }
  }

  @media screen and (min-width: 1200px) {
    .header{
      box-shadow: none;
    }
  }
