.cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    padding-block: 110px;
    gap: 20px;
}

.card{
    background-color:#00000009;
    color: #015441;
    border-radius: 15px;
    width: calc(30% - 40px);
    padding-inline: 20px;
    padding-block: 40px;
}

.card:hover, .card:focus {
    background: linear-gradient(90deg, #d3d3d3, transparent);
    background-color: #015441bb;
    color: #fff;
    transition: background-color 0.5s;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .cards{
        margin-inline: auto;
        width: 100%;
    }
    .card{
        width: 250px;
    }
  }
