.header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #015441;
    position: relative;
    min-height: 415px;
}
.headerContainer{
    position: absolute;
    z-index: 2;
    width: 100%
}

.headerContent{
    display: flex;
    flex-direction:row;
    justify-content: space-around;
    margin-top: 105px;
    max-width: 1400px;
    margin-inline: auto;
}

.contentInformationHeader{
    width: 30%;
    min-width: 400px;
}

.imageSideBanner{
    width: 60%;
}

.buttonHeader{
    background-color: #015441;
    border-radius: 10px;
    color: white;
    padding-block: 10px;
    padding-inline: 10px;
    width: inherit;
    margin-inline: auto;
}


.buttonHeader:hover{
    background-color: #015441;
    background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, .2) 100% );
    transition: background-color .9s ease-in-out;
    cursor: pointer;
}

.pattern{
    width: 100%;
    position: absolute;
    height: 390px;
    z-index: 1;
}

@media screen and (max-width: 990px) {
    .svgHide{
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .headerContent{
        flex-direction: column;
        row-gap: 20px;
    }
    .headerContent{
        width: 90%;
    }
    .svgHide{
        display: none;
    }
    .contentInformationHeader{
        min-width: 0;
        width: 100%;
    }
    .header{
        min-width: 0;
    }
    .buttonHeader{
        width: calc(100% - 20px);
    }
}

@media screen and (max-width: 400px) {
.headerContainer{
    position: relative;
}
}
